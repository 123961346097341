<script>
import Layout from "../../layouts/main";
import SessionLayout from '../../layouts/sessionLayout';
import Qrscaner from '@/components/QRcode/scanQRcode';
import ValidatePhone from '@/components/QRcode/validatePhone';
import ValidateStudentID from '@/components/QRcode/validateStudentID';
import moment from 'moment';
import errorHandler from './../../../helpers/errorHandler.js';
import Swal from 'sweetalert2';
/**
 * Starter component
 */
export default {
  page: {
    title: "Take attendance",
  },
  name: "takeAttendance",
  components:{
    Layout,
    SessionLayout,
    Qrscaner,
    ValidatePhone,
    ValidateStudentID,
  }, 
  data() {
    return {
      qrCode: null,
      qrShow: true,
      student: null,
      showStudnetDetails:false,
      checkAction: false,
      method: 1,
    };
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  },
  methods:{
    switcher(val){
      this.method = val;
    },
    getQRcode(value){
        this.qrCode = value;
        this.qrShow = false;
        this.validate(value);
    },
     async validate(value){
      await this.$store.dispatch('session/validateQRcode',value).then( (response) =>{
        this.student = response;
        if(response.student_name){
          this.showStudnetDetails = true;
        }else{
          Swal.fire({
            icon: 'error',
            title: `Not linked`,
            text: `This qr code is empty`,
            confirmButtonClass: "btn btn-confirm mt-2",
          })
          this.cancelAttendance();
        }
      }).catch(err =>{
        errorHandler.methods.error(err);
        this.qrCode= null;
        this.qrShow= true;
        this.student= null;
        this.showStudnetDetails=false;
        this.$store.dispatch('session/clearData');
      });
    },
    async takeAttendance(){
      this.checkAction = true;
      await this.$store.dispatch('session/takeAttendance',{
        qrcode:this.qrCode,
        session: this.$route.params.session,
        user_id: this.$store.getters['user/userID']
      }).then(res =>{
        if(res){
          this.qrCode= null;
          this.qrShow= true;
          this.student= null;
          this.showStudnetDetails=false;
          this.$store.dispatch('session/clearData');
          Swal.fire({
            icon: 'success',
            title: "Success",
            text: "Student attended",
            confirmButtonClass: "btn btn-confirm mt-2",
          });
        }
        this.checkAction = false;
      }).catch( err => {
        errorHandler.methods.error(err);
        this.checkAction = false;
      })
    },
    cancelAttendance(){
      this.qrCode= null;
      this.qrShow= true;
      this.student= null;
      this.showStudnetDetails=false;
      this.$store.dispatch('session/clearData');
    },
    loadImage(img){
        let path = '';
        try{
          path = `${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${img}`
          return path
        }catch(e){
          path = require('@/assets/images/users/avatar-1.jpg')
          return path;
        }
      },
  }
};
</script>

<template>
  <Layout>
    <SessionLayout :session="null">
        <div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <Qrscaner v-if="this.qrShow && this.method == 1" @qrtext="getQRcode"/>
            </div>
          </div>
          <!-- TODO:: Add phone attendance here -->
          <ValidatePhone v-if="this.qrShow && this.method == 2" @qrtext="getQRcode" />
          <ValidateStudentID v-if="this.qrShow && this.method == 3" @qrtext="getQRcode" />
          <b-row v-if="this.qrShow">
            <b-col class="col-md-4 offset-md-9 offset-0">
              <b-button variant="outline-success" pill class="m-1" @click="switcher(1)" v-if="method != 1">
                <i class="fas fa-qrcode mr-1"></i>
                  Scan QR 
              </b-button>
              <b-button variant="outline-warning" pill class="m-1" @click="switcher(2)" v-if="method != 2">
                <i class="fas fa-phone mr-1"></i>
                  Phone 
              </b-button>
              <b-button variant="outline-info" pill class="m-1" @click="switcher(3)" v-if="method != 3">
                <i class="fas fa-id-card mr-1"></i>
                  Student ID 
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="this.showStudnetDetails && this.student">
          <div class="row justify-content-center" >
              <div class="col-md-6 col-xl-8">
                <div class="widget-rounded-circle card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <div class="avatar-lg">
                                    <!-- <img :src="`${user.image}`" class="img-fluid rounded-circle" alt="user-img" /> -->
                                    <img
                                      :src="loadImage(student.image)"
                                      alt="user-image"
                                      class="img-fluid rounded-circle"
                                    />
                                </div>
                            </div>
                            <div class="col">
                                <h2 class="mt-0">{{student.student_name}}</h2>
                                <h5 class="text-muted mb-1 font-13">{{ student.phone }}</h5>
                                <h6 class="text-muted mb-1 font-13" v-if="student.attendance">Last attend:{{ student.attendance.timestamp | formatDate }}</h6>
                                <small class="text-success">
                                    <b>Student</b>
                                </small>
                            </div>
                        </div>
                    </div>
                    <!-- end row-->
                </div>
                <!-- end widget-rounded-circle-->
            </div>
          </div>
          <b-row>
            <b-col class="col-md-4 offset-md-8 offset-0">
              <b-button :disabled="checkAction" variant="outline-success" pill @click="takeAttendance">
              <i class="fas fa-check mr-1"></i>
                Attend 
              </b-button>
              <b-button variant="outline-danger" pill class="m-1" @click="cancelAttendance">
              <i class="fas fa-window-close mr-1" ></i>
                Cancel 
              </b-button>
            </b-col>
          </b-row>
        </div>
    </SessionLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
